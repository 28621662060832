export const indexMenuLinks = [
  {
    name: 'Bloggen',
    link: '/#blog',
    icon: 'mug-hot',
  },
  {
    name: 'Om projektet',
    link: '/#about',
    icon: 'broadcast-tower',
  },
  {
    name: 'Projekets delar',
    link: '/#projects',
    icon: 'terminal',
  },
  {
    name: 'Kontakt',
    link: '/#contact',
    icon: 'envelope',
  },
];

export const blogMenuLinks = [
  {
    name: 'Startsida',
    link: '/',
    icon: 'code',
  },
  {
    name: 'Blogginlägg',
    link: '/blog',
    icon: 'mug-hot',
  },
  {
    name: 'Taggar',
    link: '/tags',
    icon: 'tags',
  },
];
