export const mq = {
  xs: '@media screen and (max-width: 599px)',
  sm: '@media screen and (min-width: 600px) and (max-width: 959px)',
  md: '@media screen and (min-width: 960px) and (max-width: 1279px)',
  lg: '@media screen and (min-width: 1280px) and (max-width: 1919px)',
  xl: '@media screen and (min-width: 1920px) and (max-width: 5000px)',
  lt: {
    sm: '@media screen and (max-width: 599px)',
    md: '@media screen and (max-width: 959px)',
    lg: '@media screen and (max-width: 1279px)',
    xl: '@media screen and (max-width: 1919px)',
  },
  gt: {
    xs: '@media screen and (min-width: 600px)',
    sm: '@media screen and (min-width: 960px)',
    md: '@media screen and (min-width: 1280px)',
    lg: '@media screen and (min-width: 1920px)',
  },
};
