export const socialIconList = [{
        name: 'github',
        prefix: 'fab',
        link: 'https://github.com/newsab',
    },
    {
        name: 'facebook',
        prefix: 'fab',
        link: 'https://www.facebook.com/newsab.se',
    },
    {
        name: 'instagram',
        prefix: 'fab',
        link: 'https://www.facebook.com/newsab.se',
    },
    {
        name: 'linkedin',
        prefix: 'fab',
        link: 'https://www.linkedin.com/company/newsab',
    },
    // Add further social links with the icon of choice and link here
    // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];